<template>
	<div>
		<form name="report_form" :action="actionUrl" method="POST">
			<input type="hidden" id="_jwt_token_" name="_jwt_token_" :value="jwtToken" >
			<input type="hidden" id="tecMberSeq" name="tecMberSeq" :value="tecMberSeq" >
			<input type="hidden" id="fileName" name="fileName" :value="fileName" >
			<input type="hidden" id="issYyyy" name="issYyyy" :value="issYyyy" >
			<input type="hidden" id="issSeq" name="issSeq" :value="issSeq" >
			<input type="hidden" id="verifyVal" name="verifyVal" :value="verifyVal" >
			<input type="hidden" id="qrCd" name="qrCd" :value="qrCd" >
		</form>
	</div>
</template>

<script>
export default {
	data(){
		return {
			actionUrl : '',
			jwtToken : '',
			tecMberSeq : '',
			fileName : '',
			issYyyy : '',
			issSeq : '',
			verifyVal : '',
			qrCd : location.href,
		}
	},

	beforeMount() {

		// 관리자일 경우에는 tecMberSeq로 리포트 생성
		// 기술인재의 경우에는 jwtToken에 있는 mberSeq로 리포트 생성
		if(this.$route.query.tecMberSeq != undefined && this.$route.query.tecMberSeq != '') {
			this.tecMberSeq = this.$route.query.tecMberSeq;
		}

	},	

	mounted() {
		this.fileName = this.$route.query.fileName;
		this.issYyyy = this.$route.query.issYyyy;
		this.issSeq = this.$route.query.issSeq;
		this.verifyVal = this.$route.query.verifyVal;
		this.jwtToken = this.$store.state.userToken.token;
		this.qrCd = location.href.replace('http://localhost:8080/', 'https://13.124.153.206/');

		this.actionUrl = '/report/';

		if(window.location.hostname.indexOf('localhost') >= 0) {
			this.actionUrl = "http://localhost:8140/report/";
		}

		//console.log(this.fileName);

		// 리포트 crf 파일명을 세팅
		this.actionUrl += this.fileName;

		setTimeout(this.formSubmit, 1);
	},

	methods : {
		formSubmit() {
			document.report_form.submit();
		}
	}
}
</script>